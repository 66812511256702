import {
  Alert,
  AppBar,
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Paper,
  Snackbar,
  Tab,
  Tabs,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  ArrowBack as ArrowBackIcon,
  Language as LanguageIcon,
  Settings as SettingsIcon,
} from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import {
  addTranslation,
  deleteTranslation,
  getProject,
  getTranslations,
} from "../services/firebase-translations";
import { useNavigate, useParams } from "react-router-dom";

import ProjectSettings from "../components/ProjectSettings";
import Translations from "../components/Translations";
import { useTranslation } from "react-i18next";

const ProjectDetail = () => {
  const { projectId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState(null);
  const [translations, setTranslations] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [newTranslation, setNewTranslation] = useState({
    key: "",
    baseValue: "",
    translations: {},
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [apiKey, setApiKey] = useState(project?.apiKey || "");

  useEffect(() => {
    loadProjectData();
  }, [projectId]);

  const loadProjectData = async () => {
    try {
      const projectData = await getProject(projectId);
      setProject(projectData);

      const translationsData = await getTranslations(projectId);
      setTranslations(translationsData);
    } catch (error) {
      showSnackbar("Error loading project data", "error");
    } finally {
      setLoading(false);
    }
  };

  const handleAddTranslation = async () => {
    try {
      if (!newTranslation.key.trim()) {
        showSnackbar("Translation key is required", "error");
        return;
      }

      await addTranslation(projectId, newTranslation);
      loadProjectData();
      setOpenDialog(false);
      setNewTranslation({ key: "", baseValue: "", translations: {} });
      showSnackbar("Translation added successfully", "success");
    } catch (error) {
      showSnackbar("Error adding translation", "error");
    }
  };

  const handleDeleteTranslation = async (translationId) => {
    try {
      await deleteTranslation(translationId);
      loadProjectData();
      showSnackbar("Translation deleted successfully", "success");
    } catch (error) {
      showSnackbar("Error deleting translation", "error");
    }
  };

  const showSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <AppBar position="static" color="default" elevation={1}>
        <Toolbar>
          <IconButton
            edge="start"
            onClick={() => navigate("/dashboard")}
            sx={{ mr: 2 }}
          >
            <ArrowBackIcon />
          </IconButton>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              color="inherit"
              onClick={() => navigate("/dashboard")}
              sx={{ cursor: "pointer" }}
            >
              {t("Projects")}
            </Link>
            <Typography color="text.primary">{project?.name}</Typography>
          </Breadcrumbs>
        </Toolbar>
      </AppBar>

      <Container maxWidth="xl" sx={{ flexGrow: 1, py: 3 }}>
        <Box sx={{ mb: 4 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            {project?.name}
          </Typography>
          <Typography color="text.secondary" gutterBottom>
            {project?.description}
          </Typography>
        </Box>

        <Paper sx={{ width: "100%", mb: 3 }}>
          <Tabs
            value={activeTab}
            onChange={(e, newValue) => setActiveTab(newValue)}
            sx={{ borderBottom: 1, borderColor: "divider" }}
          >
            <Tab
              label="Translations"
              icon={<LanguageIcon />}
              iconPosition="start"
            />
            <Tab
              label="Settings"
              icon={<SettingsIcon />}
              iconPosition="start"
            />
          </Tabs>

          <Translations
            activeTab={activeTab}
            project={project}
            translations={translations}
          />
          <ProjectSettings
            activeTab={activeTab}
            apiKey={apiKey}
            projectId={projectId}
            setApiKey={setApiKey}
            showSnackbar={showSnackbar}
            loadProjectData={loadProjectData}
            project={project}
          />
        </Paper>
      </Container>

      {/* Add Translation Dialog */}
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>{t("Add New Translation")}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Translation Key"
            fullWidth
            value={newTranslation.key}
            onChange={(e) =>
              setNewTranslation({ ...newTranslation, key: e.target.value })
            }
            sx={{ mb: 2 }}
          />
          <TextField
            margin="dense"
            label={`Base Value (${project?.baseLanguage})`}
            fullWidth
            multiline
            rows={2}
            value={newTranslation.baseValue}
            onChange={(e) =>
              setNewTranslation({
                ...newTranslation,
                baseValue: e.target.value,
              })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={handleAddTranslation} variant="contained">
            {t("Add Translation")}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ProjectDetail;
