// src/services/firebase-translations.js

import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  updateDoc,
  where,
} from "firebase/firestore";

const db = getFirestore();

export const getProject = async (projectId) => {
  try {
    const projectRef = doc(db, "projects", projectId);
    const projectSnap = await getDoc(projectRef);

    if (!projectSnap.exists()) {
      throw new Error("Project not found");
    }

    return { id: projectSnap.id, ...projectSnap.data() };
  } catch (error) {
    console.error("Error getting project:", error);
    throw error;
  }
};

export const getTranslations = async (projectId) => {
  try {
    // Reference the translations subcollection within the project
    const translationsRef = collection(
      db,
      "projects",
      projectId,
      "translations"
    );
    const querySnapshot = await getDocs(translationsRef);

    // Map through the documents and format the data
    const translations = querySnapshot.docs.map((doc) => ({
      key: doc.id, // The document ID is the translation key
      ...doc.data(),
      createdAt: doc.data().createdAt?.toDate(), // Convert Firestore Timestamp to Date
      updatedAt: doc.data().updatedAt?.toDate(), // Convert Firestore Timestamp to Date
    }));

    return translations;
  } catch (error) {
    console.error("Error getting translations:", error);
    throw error;
  }
};

export const addTranslation = async (projectId, translationData) => {
  try {
    const translationsRef = collection(db, "translations");
    const newTranslation = {
      ...translationData,
      projectId,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    };

    const docRef = await addDoc(translationsRef, newTranslation);
    return { id: docRef.id, ...newTranslation };
  } catch (error) {
    console.error("Error adding translation:", error);
    throw error;
  }
};

export const updateTranslation = async (translationId, updateData) => {
  try {
    const translationRef = doc(db, "translations", translationId);
    await updateDoc(translationRef, {
      ...updateData,
      updatedAt: new Date().toISOString(),
    });
  } catch (error) {
    console.error("Error updating translation:", error);
    throw error;
  }
};

export const deleteTranslation = async (translationId) => {
  try {
    await deleteDoc(doc(db, "translations", translationId));
  } catch (error) {
    console.error("Error deleting translation:", error);
    throw error;
  }
};
