import {
  Alert,
  AppBar,
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Snackbar,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  addProject,
  deleteProject,
  getUserProjects,
} from "../services/firebase-projects";

import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import TranslateIcon from "@mui/icons-material/Translate";
import { auth } from "../firebase";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Dashboard = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);

  const [openDialog, setOpenDialog] = useState(false);
  const [newProject, setNewProject] = useState({
    name: "",
    baseLanguage: "en",
  });
  const [selectedProject, setSelectedProject] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  useEffect(() => {
    loadProjects();
  }, [user]);

  const loadProjects = async () => {
    try {
      if (user) {
        const userProjects = await getUserProjects();
        setProjects(userProjects);
      }
    } catch (error) {
      showSnackbar(t("Error loading projects"), "error");
    } finally {
      setLoading(false);
    }
  };

  const handleAddProject = async () => {
    try {
      if (!newProject.name.trim()) {
        showSnackbar(t("Project name is required"), "error");
        return;
      }

      const addedProject = await addProject({
        ...newProject,
        ownerId: auth.currentUser.uid,
      });
      setProjects([...projects, addedProject]);
      setOpenDialog(false);
      setNewProject({ name: "", description: "", baseLanguage: "en" });
      showSnackbar(t("Project created successfully"), "success");
    } catch (error) {
      showSnackbar(t("Error creating project"), "error");
    }
  };

  const handleDeleteProject = async (projectId) => {
    try {
      await deleteProject(projectId);
      setProjects(projects.filter((project) => project.id !== projectId));
      handleMenuClose();
      showSnackbar(t("Project deleted successfully"), "success");
    } catch (error) {
      showSnackbar(t("Error deleting project"), "error");
    }
  };

  const handleMenuClick = (event, project) => {
    setAnchorEl(event.currentTarget);
    setSelectedProject(project);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedProject(null);
  };

  const showSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const isProjectOwner = (project) => {
    return project.ownerId === auth.currentUser?.uid;
  };
  const renderProjectActions = (project) => {
    if (isProjectOwner(project)) {
      return (
        <CardActions sx={{ justifyContent: "space-between" }}>
          <Button
            size="small"
            onClick={() => navigate(`/projects/${project.id}`)}
          >
            {t("Open Project")}
          </Button>
          <IconButton size="small" onClick={(e) => handleMenuClick(e, project)}>
            <MoreVertIcon />
          </IconButton>
        </CardActions>
      );
    }
    return (
      <CardActions>
        <Button
          size="small"
          onClick={() => navigate(`/projects/${project.id}`)}
        >
          {t("Open Project")}
        </Button>
      </CardActions>
    );
  };

  return (
    <Box sx={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
      <AppBar position="static" color="default" elevation={1}>
        <Toolbar>
          <TranslateIcon sx={{ mr: 1, color: "primary.main" }} />
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1 }}
            color="primary"
          >
            {t("i18nPilot")}
          </Typography>
          {auth.currentUser && (
            <Avatar
              src={auth.currentUser.photoURL}
              alt={auth.currentUser.displayName}
              sx={{ cursor: "pointer" }}
              onClick={() => navigate("/profile")}
            />
          )}
        </Toolbar>
      </AppBar>

      <Container sx={{ flexGrow: 1, py: 4 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 4,
          }}
        >
          <Typography variant="h4" component="h1">
            {t("My Projects")}
          </Typography>
        </Box>

        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", py: 8 }}>
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={3}>
            {projects.map((project) => (
              <Grid item xs={12} sm={6} md={4} key={project.id}>
                <Card
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                      }}
                    >
                      <Typography variant="h6" component="h2" gutterBottom>
                        {project.name}
                      </Typography>
                      {project.ownerId === auth.currentUser?.uid && (
                        <Typography
                          variant="caption"
                          sx={{
                            bgcolor: "primary.main",
                            color: "white",
                            px: 1,
                            py: 0.5,
                            borderRadius: 1,
                          }}
                        >
                          {t("Owner")}
                        </Typography>
                      )}
                    </Box>
                    <Typography color="text.secondary" sx={{ mb: 1.5 }}>
                      {t("Base Language")}: {project.baseLanguage}
                    </Typography>
                    <Typography variant="body2">
                      {project.description}
                    </Typography>
                  </CardContent>
                  {renderProjectActions(project)}
                </Card>
              </Grid>
            ))}
          </Grid>
        )}

        <Fab
          color="primary"
          sx={{ position: "fixed", bottom: 24, right: 24 }}
          onClick={() => setOpenDialog(true)}
        >
          <AddIcon />
        </Fab>
      </Container>

      {/* Add Project Dialog */}
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>{t("Create New Project")}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label={t("Project Name")}
            fullWidth
            value={newProject.name}
            onChange={(e) =>
              setNewProject({ ...newProject, name: e.target.value })
            }
            sx={{ mb: 2 }}
          />
          <TextField
            margin="dense"
            label={t("Base Language Code")}
            fullWidth
            value={newProject.baseLanguage}
            onChange={(e) =>
              setNewProject({ ...newProject, baseLanguage: e.target.value })
            }
            helperText={t("e.g., en, es, fr")}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>{t("Cancel")}</Button>
          <Button onClick={handleAddProject} variant="contained">
            {t("Create Project")}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Project Menu */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem
          onClick={() => {
            navigate(`/projects/${selectedProject?.id}`);
            handleMenuClose();
          }}
        >
          {t("Open")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (selectedProject) handleDeleteProject(selectedProject.id);
          }}
        >
          {t("Delete")}
        </MenuItem>
      </Menu>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Dashboard;
