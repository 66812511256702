// First, install required packages:
// npm install i18next react-i18next i18next-browser-languagedetector

import LanguageDetector from "i18next-browser-languagedetector";
// src/i18n/index.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// Import all translation files
import translationEN from "./locales/en/translation.json";
import translationTR from "./locales/tr/translation.json";

const resources = {
  en: {
    translation: translationEN,
  },
  tr: {
    translation: translationTR,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en",
    debug: true,
    saveMissing: true,
    missingKeyHandler: (lngs, ns, key, fallbackValue) => {
      console.log("Missing key:", key);
      fetch("https://us-central1-i18npilot.cloudfunctions.net/addMissingKey", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          projectId: "jUVdzCnEKYbcwckJkVJG",
          key: key,
          apiKey: "oVsIGwVWYT31BAuZTsus6BiqdRE5Ecd8",
        }),
      });
    },
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ["localStorage", "navigator"],
      caches: ["localStorage"],
    },
  });

export default i18n;
