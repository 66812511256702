import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getDocs,
  getFirestore,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";

import { auth } from "../firebase";
import { customAlphabet } from "nanoid";

const db = getFirestore();
const projectsCollection = "projects";

export const addProject = async (projectData) => {
  if (!auth.currentUser) throw new Error("No authenticated user");

  try {
    const projectsRef = collection(db, projectsCollection);
    const newProject = {
      ...projectData,
      ownerId: auth.currentUser.uid,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      members: [auth.currentUser.uid],
      deleted: false,
    };
    const docRef = await addDoc(projectsRef, newProject);
    return { id: docRef.id, ...newProject };
  } catch (error) {
    console.error("Error adding project:", error);
    throw error;
  }
};

export const getUserProjects = async () => {
  if (!auth.currentUser) throw new Error("No authenticated user");

  try {
    const projectsRef = collection(db, projectsCollection);
    const q = query(
      projectsRef,
      where("members", "array-contains", auth.currentUser.uid),
      where("deleted", "==", false),
      orderBy("createdAt", "desc")
    );
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  } catch (error) {
    console.error("Error getting projects:", error);
    throw error;
  }
};

export const deleteProject = async (projectId) => {
  try {
    const projectRef = doc(db, projectsCollection, projectId);
    await updateDoc(projectRef, {
      deleted: true,
      updatedAt: new Date().toISOString(),
    });
  } catch (error) {
    console.error("Error deleting project:", error);
    throw error;
  }
};

export const updateProject = async (projectId, updateData) => {
  try {
    const projectRef = doc(db, projectsCollection, projectId);
    await updateDoc(projectRef, {
      ...updateData,
      updatedAt: new Date().toISOString(),
    });
  } catch (error) {
    console.error("Error updating project:", error);
    throw error;
  }
};

export const addProjectMember = async (projectId, memberEmail) => {
  try {
    const memberUserId = "user-id-from-email";
    const projectRef = doc(db, projectsCollection, projectId);
    await updateDoc(projectRef, {
      members: arrayUnion(memberUserId),
      updatedAt: new Date().toISOString(),
    });
  } catch (error) {
    console.error("Error adding project member:", error);
    throw error;
  }
};

export const removeProjectMember = async (projectId, memberId) => {
  try {
    const projectRef = doc(db, projectsCollection, projectId);
    await updateDoc(projectRef, {
      members: arrayRemove(memberId),
      updatedAt: new Date().toISOString(),
    });
  } catch (error) {
    console.error("Error removing project member:", error);
    throw error;
  }
};

const generateApiKey = customAlphabet(
  "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz",
  32
);

export const generateNewApiKey = async (projectId) => {
  try {
    const apiKey = generateApiKey();
    const projectRef = doc(db, "projects", projectId);

    await updateDoc(projectRef, {
      apiKey,
      apiKeyCreatedAt: new Date().toISOString(),
    });

    return apiKey;
  } catch (error) {
    console.error("Error generating API key:", error);
    throw error;
  }
};

export const revokeApiKey = async (projectId) => {
  try {
    const projectRef = doc(db, "projects", projectId);
    await updateDoc(projectRef, {
      apiKey: null,
      apiKeyCreatedAt: null,
    });
  } catch (error) {
    console.error("Error revoking API key:", error);
    throw error;
  }
};
