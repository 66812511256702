import { Button, Stack, Typography } from "@mui/material";

import React from "react";
import { useTranslation } from "react-i18next";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const changeLanguage = () => {
    const newLang = i18n.language === "en" ? "tr" : "en";
    i18n.changeLanguage(newLang);
  };

  return (
    <Button variant="contained" onClick={changeLanguage} size="small">
      {i18n.language.toUpperCase()}
    </Button>
  );
};
export default LanguageSwitcher;
