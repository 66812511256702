import {
  AppBar,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { signInWithGoogle, signOutUser } from "../services/firebase-auth";

import FolderIcon from "@mui/icons-material/Folder";
import GoogleIcon from "@mui/icons-material/Google";
import LanguageSwitcher from "../components/LanguageSwitcher";
import LoginIcon from "@mui/icons-material/Login";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import TranslateIcon from "@mui/icons-material/Translate";
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const LandingPage = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });

    return () => unsubscribe();
  }, []);

  const handleGoogleSignIn = async () => {
    try {
      await signInWithGoogle();
      // Redirect to dashboard or other protected route
      navigate("/dashboard");
    } catch (error) {
      console.error("Sign in error:", error);
      // Handle error (show notification, etc.)
    }
  };

  const handleSignOut = async () => {
    try {
      await signOutUser();
      handleMenuClose();
    } catch (error) {
      console.error("Sign out error:", error);
    }
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const features = [
    {
      title: t("Missing Value Detection"),
      description: t(
        "Automatically scan and identify missing translations across your projects"
      ),
      icon: <ManageSearchIcon sx={{ fontSize: 40, color: "primary.main" }} />,
    },
    {
      title: t("Project Management"),
      description: t(
        "Organize translations by projects and manage them efficiently"
      ),
      icon: <FolderIcon sx={{ fontSize: 40, color: "primary.main" }} />,
    },
    {
      title: t("Directory Structure"),
      description: t(
        "Maintain clean directory structures for your translation files"
      ),
      icon: <TranslateIcon sx={{ fontSize: 40, color: "primary.main" }} />,
    },
  ];

  return (
    <Box sx={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
      <AppBar position="static" color="default" elevation={1}>
        <Toolbar>
          <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
            <TranslateIcon sx={{ mr: 1, color: "primary.main" }} />
            <Typography variant="h6" component="div" color="primary">
              {t("i18nPilot")}
            </Typography>
          </Box>
          <LanguageSwitcher />
          {user ? (
            <>
              <Avatar
                src={user.photoURL}
                alt={user.displayName}
                sx={{ ml: 2, cursor: "pointer" }}
                onClick={handleMenuClick}
              />
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={() => navigate("/dashboard")}>
                  {t("Dashboard")}
                </MenuItem>
                <MenuItem onClick={() => navigate("/profile")}>
                  {t("Profile")}
                </MenuItem>
                <MenuItem onClick={handleSignOut}>{t("Sign Out")}</MenuItem>
              </Menu>
            </>
          ) : (
            <Button
              variant="outlined"
              startIcon={<LoginIcon />}
              onClick={handleGoogleSignIn}
            >
              {t("Sign in")}
            </Button>
          )}
        </Toolbar>
      </AppBar>

      <Container component="main" sx={{ flexGrow: 1, py: 8 }}>
        <Box sx={{ textAlign: "center", mb: 8 }}>
          <Typography
            variant="h2"
            component="h1"
            gutterBottom
            sx={{
              fontWeight: "bold",
              mb: 4,
            }}
          >
            {t("Simplify Your i18n Workflow")}
          </Typography>
          <Typography
            variant="h5"
            color="text.secondary"
            sx={{ maxWidth: 800, mx: "auto", mb: 6 }}
          >
            {t(
              "Automatically detect missing translations, manage multiple projects, and streamline your localization process with i18nPilot."
            )}
          </Typography>

          <Grid container spacing={4} sx={{ mb: 8 }}>
            {features.map((feature, index) => (
              <Grid item xs={12} md={4} key={`feature-${feature.title}`}>
                <Card
                  elevation={2}
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    "&:hover": {
                      transform: "translateY(-4px)",
                      transition: "transform 0.3s ease-in-out",
                    },
                  }}
                >
                  <CardContent sx={{ textAlign: "center", flexGrow: 1 }}>
                    <Box sx={{ mb: 2 }}>{feature.icon}</Box>
                    <Typography variant="h6" component="h3" gutterBottom>
                      {feature.title}
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                      {feature.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>

          {!user && (
            <Button
              variant="contained"
              size="large"
              startIcon={<GoogleIcon />}
              onClick={handleGoogleSignIn}
              sx={{
                py: 2,
                px: 4,
                fontSize: "1.1rem",
              }}
            >
              {t("Get Started with Google")}
            </Button>
          )}
        </Box>
      </Container>

      <Box
        component="footer"
        sx={{
          py: 3,
          px: 2,
          mt: "auto",
          backgroundColor: theme.palette.background.paper,
          borderTop: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Typography variant="body2" color="text.secondary" align="center">
          {t("© 2024 i18nPilot. All rights reserved.")}
        </Typography>
      </Box>
    </Box>
  );
};

export default LandingPage;
