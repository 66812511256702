import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  ContentCopy as ContentCopyIcon,
  Delete as DeleteIcon,
  Key as KeyIcon,
} from "@mui/icons-material";
import React, { useState } from "react";
import {
  deleteProject,
  generateNewApiKey,
  revokeApiKey,
} from "../services/firebase-projects";

import { useTranslation } from "react-i18next";

const TabPanel = ({ children, value, index, ...other }) => (
  <div role="tabpanel" hidden={value !== index} {...other}>
    {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
  </div>
);

const ProjectSettings = ({
  activeTab,
  apiKey,
  showSnackbar,
  setApiKey,
  projectId,
  loadProjectData,
  project,
}) => {
  const [showApiKey, setShowApiKey] = useState(false);
  const [copied, setCopied] = useState(false);
  const { t } = useTranslation();
  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (error) {
      showSnackbar("Error copying to clipboard", "error");
    }
  };

  const handleRevokeApiKey = async () => {
    try {
      await revokeApiKey(projectId);
      setApiKey("");
      showSnackbar("API key revoked successfully", "success");
      loadProjectData();
    } catch (error) {
      showSnackbar("Error revoking API key", "error");
    }
  };

  const handleGenerateApiKey = async () => {
    try {
      const newApiKey = await generateNewApiKey(projectId);
      setApiKey(newApiKey);
      showSnackbar("API key generated successfully", "success");
      loadProjectData();
    } catch (error) {
      showSnackbar("Error generating API key", "error");
    }
  };

  const handleDeleteProject = async () => {
    try {
      await deleteProject(projectId);
      showSnackbar("Project deleted successfully", "success");
      loadProjectData();
    } catch (error) {
      showSnackbar("Error deleting project", "error");
    }
  };

  const getEndpointUrl = () => {
    return `https://us-central1-i18npilot.cloudfunctions.net/addMissingKey`;
  };

  const getUsageExample = () => {
    return `// Example: Adding a new translation key
fetch('${getEndpointUrl()}', {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json'
  },
  body: JSON.stringify({
    projectId: '${projectId}',
    key: 'welcome.message',
    baseValue: 'Welcome to our application',
    apiKey: '${apiKey || "your-api-key"}'
  })
})
.then(response => response.json())
.then(data => {
  if (data.success) {
    console.log('Translation key added:', data.data);
  } else {
    console.error('Error:', data.error);
  }
})
.catch(error => console.error('Error:', error));

/* Response example:
{
  "success": true,
  "message": "Translation key added successfully",
  "data": {
    "projectId": "${projectId}",
    "key": "welcome.message",
    "baseValue": "Welcome to our application"
  }
}
*/`;
  };

  return (
    <TabPanel value={activeTab} index={1}>
      <Stack spacing={4}>
        <Card>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              API Configuration
            </Typography>
            <Divider sx={{ mb: 3 }} />

            <Stack spacing={3}>
              <Box>
                <Typography variant="subtitle1" gutterBottom>
                  API Key
                </Typography>
                {apiKey ? (
                  <>
                    <TextField
                      fullWidth
                      value={showApiKey ? apiKey : "•".repeat(32)}
                      InputProps={{
                        readOnly: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip title="Toggle visibility">
                              <IconButton
                                onClick={() => setShowApiKey(!showApiKey)}
                              >
                                <KeyIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip
                              title={copied ? "Copied!" : "Copy API key"}
                            >
                              <IconButton
                                onClick={() => copyToClipboard(apiKey)}
                              >
                                <ContentCopyIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Revoke API key">
                              <IconButton
                                onClick={handleRevokeApiKey}
                                color="error"
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Typography
                      variant="caption"
                      color="text.secondary"
                      sx={{ mt: 1, display: "block" }}
                    >
                      Created at:{" "}
                      {new Date(project?.apiKeyCreatedAt).toLocaleString()}
                    </Typography>
                  </>
                ) : (
                  <Button
                    variant="outlined"
                    startIcon={<KeyIcon />}
                    onClick={handleGenerateApiKey}
                  >
                    Generate API Key
                  </Button>
                )}
              </Box>

              <Box>
                <Typography variant="subtitle1" gutterBottom>
                  Endpoint URL
                </Typography>
                <TextField
                  fullWidth
                  value={getEndpointUrl()}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title={copied ? "Copied!" : "Copy URL"}>
                          <IconButton
                            onClick={() => copyToClipboard(getEndpointUrl())}
                          >
                            <ContentCopyIcon />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>

              <Box>
                <Typography variant="subtitle1" gutterBottom>
                  Usage Example
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={16}
                  value={getUsageExample()}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title={copied ? "Copied!" : "Copy code"}>
                          <IconButton
                            onClick={() => copyToClipboard(getUsageExample())}
                          >
                            <ContentCopyIcon />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>

              <Alert severity="info">
                <AlertTitle>API Usage Notes</AlertTitle>
                <List dense>
                  <ListItem>
                    <ListItemText
                      primary="Authentication"
                      secondary="Include your API key in the request body for authentication"
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Required Fields"
                      secondary="projectId, key, baseValue, and apiKey are all required fields"
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Translation Keys"
                      secondary="Keys should be unique within your project and use dot notation (e.g., 'welcome.message')"
                    />
                  </ListItem>
                </List>
              </Alert>
            </Stack>
          </CardContent>
        </Card>
      </Stack>
      <Button variant="contained" color="error" onClick={handleDeleteProject}>
        {t("Delete Project")}
      </Button>
    </TabPanel>
  );
};

export default ProjectSettings;
