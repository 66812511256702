import { GoogleAuthProvider, getAuth } from "firebase/auth";

import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyASYZsi-vcE7L7h3XqyTorvPvY8osSYxmk",
  authDomain: "i18npilot.firebaseapp.com",
  projectId: "i18npilot",
  storageBucket: "i18npilot.firebasestorage.app",
  messagingSenderId: "569366139329",
  appId: "1:569366139329:web:d33638240f52e473b95f40",
  measurementId: "G-CE9GXSS2Y4",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth();
const provider = new GoogleAuthProvider();
const db = getFirestore(app);
const functions = getFunctions(app);

export { auth, provider, db, functions, analytics };
