import {
  Alert,
  Box,
  Button,
  IconButton,
  Paper,
  Snackbar,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  ContentCopy as ContentCopyIcon,
  Save as SaveIcon,
} from "@mui/icons-material";
import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

const TabPanel = ({ children, value, index, ...other }) => (
  <div role="tabpanel" hidden={value !== index} {...other}>
    {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
  </div>
);

export default function Translations({ project, activeTab, translations }) {
  const { t } = useTranslation();
  const [jsonContent, setJsonContent] = useState("");
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  useEffect(() => {
    // Convert translations array to JSON format
    const jsonFormat = translations.reduce((acc, curr) => {
      if (!acc[curr.key]) {
        acc[curr.key] = curr.key;
      }
      return acc;
    }, {});

    setJsonContent(JSON.stringify(jsonFormat, null, 2));
  }, [translations]);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(jsonContent);
      setSnackbar({
        open: true,
        message: "Copied to clipboard!",
        severity: "success",
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Failed to copy",
        severity: "error",
      });
    }
  };

  return (
    <TabPanel value={activeTab} index={0}>
      <Box sx={{ mb: 2, display: "flex", justifyContent: "flex-end" }}>
        <Tooltip title="Copy JSON">
          <IconButton onClick={handleCopy}>
            <ContentCopyIcon />
          </IconButton>
        </Tooltip>
      </Box>

      <Paper
        elevation={1}
        sx={{
          //   backgroundColor: "#1e1e1e",
          borderRadius: 1,
        }}
      >
        <pre
          style={{
            margin: 0,
            padding: "16px",
            outline: "none",
            minHeight: "500px",
          }}
        >
          <code
            style={{
              //   color: "#d4d4d4",
              fontSize: "14px",
              fontFamily:
                'Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace',
              lineHeight: "1.5",
              whiteSpace: "pre",
              wordSpacing: "normal",
              wordBreak: "normal",
              wordWrap: "normal",
              display: "block",
            }}
          >
            {jsonContent}
          </code>
        </pre>
      </Paper>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert severity={snackbar.severity} sx={{ width: "100%" }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </TabPanel>
  );
}
